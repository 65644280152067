import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import {OfferTemplateCategoryService} from "@/slices/OfferTemplateCategory/service/offer-template-category.service";
import {offerTemplateCategoryStore} from "@/slices/OfferTemplateCategory/store";
import {categoryFormStore} from "@/slices/OfferTemplateCategory/categoryFormStore";

async function beforeEnterCategory(to: any, from: any, next: any) {
  const id = to.params.id as string
  const store = offerTemplateCategoryStore()
  const categoryStore = categoryFormStore()

  const categoryFromApi = await OfferTemplateCategoryService().findOneById(id);

  if(categoryFromApi) {
    store.putCategory(categoryFromApi)
    categoryStore.initializeFromIncomingData(categoryFromApi)
  } else   {
    next('/not-found');
  }

  next();
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/calculator/',
    name: 'calculatorList',
    component: () => import(/* webpackChunkName: "calculator" */ '../slices/OfferTemplateCalcultor/views/CalculatorsList.vue')
  },
  {
    path: '/calculator/edit/:id',
    name: 'calculator',
    component: () => import(/* webpackChunkName: "calculator" */ '../slices/OfferTemplateCalcultor/views/EditCalculator.vue')
  },
  {
    path: '/client/:templateId',
    name: 'client-calculator',
    component: () => import(/* webpackChunkName: "quote" */ '../slices/OfferTemplateCalcultor/views/ClientOfferCalculator.vue')
  },
  {
    path: '/category',
    name: 'category',
    component: () => import(/* webpackChunkName: "category" */ '../slices/OfferTemplateCategory/views/CategoryList.vue')
  },
  {
    path: '/not-found',
    name: 'not-found',
    component: import(/* webpackChunkName: "category" */ '../NotFound.vue')
  },
  {
    path: '/category/view/:id',
    name: 'category-add',
    beforeEnter: async (to, from, next) => {
      return next();
    },
    component: () => import(/* webpackChunkName: "category" */ '../slices/OfferTemplateCategory/views/CategoryView.vue')
  },
  {
    path: '/category/edit/:id',
    name: 'category-edit',
    beforeEnter: beforeEnterCategory,
    component: () => import(/* webpackChunkName: "category" */ '../slices/OfferTemplateCategory/views/CategoryView.vue')
  },
  {
    path: '/category/edit/:id/template/:templateId',
    name: 'category-edit-with-template-context',
    beforeEnter: beforeEnterCategory,
    component: () => import(/* webpackChunkName: "category" */ '../slices/OfferTemplateCategory/views/CategoryView.vue')
  },
  {
    path: '/category/:id/products',
    name: 'category-products',
    beforeEnter: beforeEnterCategory,
    component: () => import(/* webpackChunkName: "category" */ '../slices/OfferTemplateCategory/views/CategoryView.vue')
  },
  {
    path: '/category/:id/product/:productId',
    name: 'product-view',
    beforeEnter: beforeEnterCategory,
    component: () => import(/* webpackChunkName: "product" */ '../slices/Products/components/ProductForm.vue')
  },
  {
    path: '/install',
    name: 'install-bitrix',
    component: () => import('../InstallBitrix.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
