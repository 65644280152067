import {
    IncomingOfferCategoryData,
    OfferCategory,
    OfferCategoryInterface
} from "@/slices/OfferTemplateCategory/interfaces/offer-category.interface";
import {axiosInstance} from "@/clients/defaultBackendClient";
import { v4 as uuidv4 } from "uuid";
import {AxiosResponse} from "axios";
import {offerTemplateCategoryStore} from "@/slices/OfferTemplateCategory/store";

export const OfferTemplateCategoryService = () => {

    const store = offerTemplateCategoryStore();

    const findAll = async () => {
        const response = await axiosInstance.get<any, AxiosResponse<OfferCategory[]>>(`offer-category`)

        if (response.status !== 200) {
            throw new Error('Error')
        }

        store.categories = response.data;

        return response.data;
    }

    const findOneById = async (id: string): Promise<IncomingOfferCategoryData> => {
        const response = await axiosInstance.get<any, AxiosResponse<IncomingOfferCategoryData>>(`offer-category/${id}`)

        if(response.status!== 200) {
            throw new Error('Error fetching offer category')
        }

        return response.data;
    }

    const persist = async (offerCategory: OfferCategoryInterface) => {

        const requestData = {
            ...offerCategory,
            id: offerCategory.id || uuidv4()
        }

        console.log('Request data', requestData)

        const response = await axiosInstance.put(`offer-category/${requestData.id}`, requestData);

        if (response.status !== 200) {
            throw new Error('Error during offer template category persist');
        }

        return response.data;
    }

    const remove = async (id: string) => {
        const response = await axiosInstance.delete('/offer-category/' + id);

        return response.data;
    }


    return {
        findAll,
        findOneById,
        persist,
        remove,
    }
}