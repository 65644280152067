import {
    CalculationOfferCategoryInterface,
    Calculator,
    CharacteristicInterface,
    DefaultCalculationOfferCategoryInterface,
    FractionOfferCategoryInterface, IncomingOfferCategoryData,
    OfferCategory,
    OfferCategoryType
} from "@/slices/OfferTemplateCategory/interfaces/offer-category.interface";
import {v4 as uuidv4} from "uuid";


const nullCategory = {
    id: uuidv4(),
    name: '',
    characteristics: {
        id: '',
        name: '',
    } as CharacteristicInterface,
    type: OfferCategoryType.STANDARD as OfferCategoryType,
    isRequired: false,
    products: [],
    allowDuplicate: false,
    bitrixSectionId: '',
    isVisibleForClient: true,
    isVisibleForClientInGenerator: true,
    allowDuplicates: false,
    measurement: '',
    dependantCategories: [],
    displayOrder: null,
} as DefaultCalculationOfferCategoryInterface

const nullCalculationCategory = {
    ...nullCategory,
    dependantCategory: [] as OfferCategory[],
    rangeCollection: []
} as CalculationOfferCategoryInterface

const nullFractionCategory = {
    ...nullCategory,
    fraction: 0,
    userEditable: false,
    dependantCategories: []
} as FractionOfferCategoryInterface

export class OfferCategoryFactory {
    static createByType(type: OfferCategoryType): OfferCategory {
        const map = {
            [OfferCategoryType.STANDARD]: nullCategory,
            [OfferCategoryType.DEPENDENT_CATEGORY_FRACTION]: nullFractionCategory,
            [OfferCategoryType.CUSTOM_CALCULATION]: nullCalculationCategory,
            [OfferCategoryType.SERVICE]: null,
        }

        const category =  map[type];
        if(!category) {
            throw new Error('Offer Category type not found')
        }

        return category
    }

    static createToFormComponent(category: IncomingOfferCategoryData) {
        return category;
    }
}
