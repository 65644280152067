import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import "bootstrap/dist/css/bootstrap.min.css"
import "./assets/css/main.scss"
import "bootstrap"
import {createPinia} from "pinia";

const app = createApp(App)
const pinia = createPinia();


declare global {
    interface Window {
        initialData: {
            PLACEMENT: string
        };
    }
}

app.use(pinia)
app.use(router);
app.mount('#app');
