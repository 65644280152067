import {defineStore} from "pinia";
import {
    CalculationOfferCategoryInterface,
    CharacteristicInterface,
    DefaultCalculationOfferCategoryInterface,
    FractionOfferCategoryInterface,
    OfferCategory,
    OfferCategoryType
} from "@/slices/OfferTemplateCategory/interfaces/offer-category.interface";
import {v4 as uuidv4} from "uuid";
import {OfferTemplateInterface} from "@/slices/OfferTemplateCalcultor/interfaces/OfferTemplateInterface";
import {OfferCategoryFactory} from "@/slices/OfferTemplateCategory/service/factory/offer-category.factory";

export const offerTemplateCategoryStore = defineStore({
    id: 'offer-template-category-store',
    state: () => {
        return {
            categories: [] as OfferCategory[],
            currentlyEditing: null as string | null,
            characteristics: [] as CharacteristicInterface[],
        }
    },
    getters: {
        getAll: (state) => function () {
            return state.categories as OfferCategory[]
        },
        getOneById: (state) => function(categoryId: string) {
            return state.categories.find(category => category.id === categoryId)
        },
        getCurrentCategory: (state) => function(type?: OfferCategoryType): OfferCategory {
            if(!state.currentlyEditing) {
                return OfferCategoryFactory.createByType(OfferCategoryType.STANDARD)
            }

            const foundCategory = state.categories.find(category => category.id === state.currentlyEditing)
            if(!foundCategory) {
                return OfferCategoryFactory.createByType(OfferCategoryType.STANDARD)
            }

            return foundCategory;
        },
        getCharacteristics: (state) => { // should not be here I think
            return state.characteristics
        }
    },
    actions: {
        addCategory: function(category: OfferCategory) {
            this.categories.push(category)
        },
        addCharacteristic: function (characteristicName: string) {
            const characteristic = {
                id: uuidv4(),
                name: characteristicName
            }
            this.characteristics.push(characteristic)

            return characteristic
        },
        putCategory: function (category: OfferCategory) {
            const existingCategoryInStore = this.getOneById(category.id)
            if(!existingCategoryInStore) {
                this.addCategory(category);
            }

            const filteredCategories = this.categories.filter(_category => {
                return _category.id !== category.id
            })

            this.categories = [...filteredCategories, category]
        }
    }
})