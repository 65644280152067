import {defineStore} from "pinia";
import {
    CalculationOfferCategoryData,
    Calculatortype,
    DefaultCalculationOfferCategoryData,
    FractionOfferCategoryData,
    IncomingOfferCategoryData,
    OfferCategory,
    OfferCategoryInterface,
    OfferCategoryType
} from "@/slices/OfferTemplateCategory/interfaces/offer-category.interface";

export const categoryFormStore = defineStore({
    id: 'category-form-store',
    state: () => ({
            category: {} as OfferCategoryInterface,
            defaultData: {} as DefaultCalculationOfferCategoryData,
            fractionData: {} as FractionOfferCategoryData,
            calculationData: {} as CalculationOfferCategoryData,
            availableDependentCategories: [] as OfferCategory[]
    }),
    actions: {
        initializeCurrent: function (offerCategory: OfferCategory) {
          this.category = offerCategory
        },
        initializeNewCategory: function () {
            this.category = {
                id: '',
                name: '',
                isRequired: false,
                allowDuplicates: false,
                isVisibleForClient: true,
                isVisibleForClientInGenerator: true,
                type: OfferCategoryType.STANDARD,
                characteristics: {
                    id: '',
                    name: ''
                },
                dependantCategories: [] as OfferCategory[],
                measurement: 'szt.',
                displayOrder: null,
            }

            this.fractionData = {
                dependantCategories: [],
                userEditable: false,
                fraction: 0
            }

            this.calculationData = {
                rangeCollection: [],
                dependantCategory: [] as OfferCategory[]
            }
        },
        initializeFromIncomingData: function (data: IncomingOfferCategoryData) {
            this.category = {
                id: data.id,
                name: data.name,
                isRequired: data.isRequired,
                allowDuplicates: data.allowDuplicates,
                isVisibleForClient: data.isVisibleForClient,
                isVisibleForClientInGenerator: data.isVisibleForClientInGenerator,
                type: data.type,
                characteristics: data.characteristics || {
                    id: '',
                    name: ''
                },
                dependantCategories: data.type === OfferCategoryType.STANDARD ? data.dependantCategories : [],
                measurement: data.measurement || '',
                calculatorName: data.calculatorName as Calculatortype,
                displayOrder: data.displayOrder,
            }
            this.defaultData = {
                bitrixSectionId: data.bitrixSectionId || '',
            }
            this.fractionData = {
                dependantCategories: data.type === OfferCategoryType.DEPENDENT_CATEGORY_FRACTION ? data.dependantCategories : [],
                userEditable: data.userEditable,
                fraction: data.fraction
            }
            this.calculationData = {
                rangeCollection: data.rangeCollection,
                dependantCategory: data.type === OfferCategoryType.CUSTOM_CALCULATION ? data.dependantCategories : []
            }
        },
        updateAvailableDependentCategories: function (categories: OfferCategory[]) {
            this.availableDependentCategories = categories
        },
        updateFractionDependentCategories: function (categoryIds: string[]) {
            const categories = this.availableDependentCategories.filter(category => {
                return categoryIds.includes(category.id)
            })

            this.fractionData.dependantCategories = categories
        },
        updateCalculationDependentCategory: function (categoryId: string) {
            const categories = this.availableDependentCategories.filter(category => {
                return category.id === categoryId
            })

            this.calculationData.dependantCategory = categories
        },
        updateDefaultCalculationDependentCategory: function (categoryIds: string[]) {
            const categories = this.availableDependentCategories.filter(category => {
                return categoryIds.includes(category.id)
            })
            this.category.dependantCategories = categories;
        },
        updateCalculatorType: function (calculatorName: Calculatortype) {
            this.category.calculatorName = calculatorName
        },
    },
    getters: {
        getCategory: (state) => state.category,
        getDefaultCalculationOfferCategoryData: (state) => state.defaultData,
        getFractionOfferCategoryData: (state) => state.fractionData,
        getCalculationOfferCategoryData: (state) => state.calculationData,
        getStandardCategoriesOnly: (state) => state.availableDependentCategories.filter(category => {
            return category.type === OfferCategoryType.STANDARD
        })
    }
})
