import axios from 'axios';

// Create a new Axios instance with custom defaults
export const axiosInstance = axios.create({
    baseURL: `${process.env.VUE_APP_BACKEND_URL}`, // Base URL for all requests
    timeout: 15000, // Request timeout in milliseconds
    headers: {
        'Content-Type': 'application/json', // Default content type
        'Authorization': 'Bearer your-token-here' // Default authorization header
    }
});
